import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [language, setLanguage] = useState(localStorage.getItem("language") ? localStorage.getItem("language") : "tr");
  const { t, i18n } = useTranslation("global");

  const headerItemsCount = t("header", { returnObjects: true }).length;

  const changeLanguageHandler = (language) => {
    i18n.changeLanguage(language);
    localStorage.setItem("language", language);
    setLanguage(language)
  };

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language, i18n]);

  return (
    <div className="max-md:mb-[68px] mb-[113px]">
      <nav
        className={`bg-white shadow-lg transition-all fixed w-full top-0 left-0 `}
        style={{ zIndex: 9999 }}
      >
        <div className="relative max-w-7xl mx-auto px-4">
          <div className="flex justify-between items-center py-3">
            <div className="">
              <a href="/">
                <img
                  src="/images/logo.png"
                  alt="logo-img"
                  className="max-md:w-40 w-80"
                />
              </a>
            </div>

            <div className="md:hidden flex items-center gap-2">
            <div className="">
                <a
                 className="block py-2 px-4 text-sm text-gray-700 bg-gray-200 hover:bg-orange-500 hover:text-white cursor-pointer"
                  onClick={() =>
                    changeLanguageHandler(language === "tr" ? "en" : "tr")
                  }
                >
                  {language === "tr" ? "English" : "Türkçe"}
                </a>
              </div>
              <div className="hidden max-lg:block relative">
                <i
                  className={`${
                    isOpen ? "ri-close-large-line" : "ri-menu-line"
                  } transition-all bg-orange-500 text-white p-3`}
                  onClick={() => {
                    setIsOpen(!isOpen);
                  }}
                />
              </div>
            </div>

            {/* Links for desktop */}
            <div className="hidden md:flex lg:space-x-6 space-x-2">
              {Array.from({ length: headerItemsCount }, (_, index) => (
                <a
                  key={index}
                  href={t(`header.${index}.url`)}
                  className="hover:font-bold transition-all"
                >
                  {t(`header.${index}.title`)}
                </a>
              ))}
              <div className="">
                <button
                  onClick={() =>
                    changeLanguageHandler(language === "tr" ? "en" : "tr")
                  }
                >
                  {language === "tr" ? "English" : "Türkçe"}
                </button>
              </div>
            </div>
          </div>

          {/* Links for mobile */}
          {isOpen && (
            <div
              className={`md:hidden absolute bg-white w-full left-0 pb-10 ${
                isOpen && "border-t"
              }`}
            >
              {Array.from({ length: headerItemsCount }, (_, index) => (
                <a
                  key={index}
                  href={t(`header.${index}.url`)}
                   className="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-200"
                >
                  {t(`header.${index}.title`)}
                </a>
              ))}
             
            </div>
          )}
        </div>
      </nav>
    </div>
  );
};

export default Header;
