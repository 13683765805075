import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { blogs } from "../../constants/blogs";
import Reveal from "../layouts/Reveal";
import MetaData from "../layouts/MetaData";
import { useTranslation } from "react-i18next";

const Blog = () => {
  const [language, setLanguage] = useState(
    localStorage.getItem("language") ? localStorage.getItem("language") : "tr"
  );
  const { t, i18n } = useTranslation("global");
  const itemsCount = t("blogs", { returnObjects: true }).length;

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language, i18n]);
  return (
    <div className="min-h-screen max-sm:px-4 sm:px-16">
      <MetaData title={"Bloglarımız"} />

      <Reveal>
        <div className="flex flex-col items-center">
          <h1 className="text-4xl font-bold mt-10 mb-3">
            {t("blogPage.title")}
          </h1>
          <p>{t("blogPage.text")}</p>
        </div>

        <div className="grid max-md:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 mt-10 gap-10">
          {Array.from({ length: itemsCount }, (_, index) => (
            <Link to={`/blog/${t(`blogs.${index}.slug`)}`}>
              <div className="blog-card flex flex-col gap-5 cursor-pointer">
                <img
                  src={t(`blogs.${index}.img`)}
                  className="rounded-ss-xl rounded-se-xl object-cover max-h-52"
                  alt=""
                />
                <div className="px-3">
                  <h1 className="text-2xl font-bold">
                    {t(`blogs.${index}.title`)}
                  </h1>
                  <p>{t(`blogs.${index}.subtitle`)}</p>
                </div>
                <span className="px-3">{t(`blogs.${index}.createdAt`)}</span>
                <Link
                  className="border text-center py-3 rounded-md hover:bg-black hover:text-white"
                  to={`/blog/${t(`blogs.${index}.slug`)}`}
                >
                  Detaylara Git
                </Link>
              </div>
            </Link>
          ))}
        </div>
      </Reveal>
    </div>
  );
};

export default Blog;
