import React, { useEffect, useState } from "react";
import MetaData from "../layouts/MetaData";
import Reveal from "../layouts/Reveal";
import { useTranslation } from "react-i18next";

const OurMission = () => {
  const [language, setLanguage] = useState(
    localStorage.getItem("language") ? localStorage.getItem("language") : "tr"
  );

  const { t, i18n } = useTranslation("global");
  const arrayData = t("ourMissionPage.ourQualityGoals.items", { returnObjects: true });

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language, i18n]);
  return (
    <div className="">
      <MetaData title={`Misyonumuz`} />

      <div className="">
        <img
          src="/images/our-mission-img.png"
          alt="img"
          className="w-full h-[500px] object-cover"
        />
      </div>

      <div className="max-lg:px-10 lg:px-64 mt-10">
        <Reveal>
          <div className="mt-10 ">
            <h1 className="text-center text-4xl font-bold mb-3">{t(`ourMissionPage.ourMission.title`)}</h1>
            <p>{t(`ourMissionPage.ourMission.text`)}</p>
          </div>
        </Reveal>

        <Reveal>
          <div className="mt-10 ">
            <h1 className="text-center text-4xl font-bold mb-3">{t(`ourMissionPage.ourVision.title`)}</h1>
            <p>{t(`ourMissionPage.ourVision.text`)}</p>
          </div>
        </Reveal>

        <Reveal>
          <div className="mt-10 ">
            <div className="">
              <h1 className="text-center text-4xl font-bold mb-3">
              {t(`ourMissionPage.ourQualityGoals.title`)}
              </h1>
              <p>{t(`ourMissionPage.ourQualityGoals.text`)}</p>
            </div>
            <div className="flex max-lg:flex-col gap-5 mt-10">
              <div className="flex-grow-0">
                <img src={t(`ourMissionPage.ourQualityGoals.img`)} className="" alt="" />
              </div>
              <div className="flex flex-col gap-5">
                {arrayData?.map((data) => (
                  <div className="flex items-center gap-2">
                  <i class="ri-check-line text-orange-500 text-2xl font-bold" />
                  <p>
                    <span className="font-bold">{data?.span}</span>
                    {data?.text}
                  </p>
                </div>
                ))}
              </div>
            </div>
          </div>
        </Reveal>
      </div>
    </div>
  );
};

export default OurMission;
