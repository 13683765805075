import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Home from './components/pages/Home';
import Footer from './components/layouts/Footer';
import Header from './components/layouts/Header';
import Blog from './components/pages/Blog';
import BlogDetails from './components/pages/BlogDetails';
import AboutUs from './components/pages/AboutUs';
import OurActivities from './components/pages/OurActivities';
import OurMission from './components/pages/OurMission';
import './App.css';
import 'leaflet/dist/leaflet.css';
import Communication from './components/pages/Communication';

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/bloglar' element={<Blog />} />
        <Route path='/blog/:slug' element={<BlogDetails />} />
        <Route path='/hakkimizda' element={<AboutUs />} />
        <Route path='/faaliyetlerimiz' element={<OurActivities />} />
        <Route path='/misyonumuz' element={<OurMission />} />
        <Route path='/iletisim' element={<Communication />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
