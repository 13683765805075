import React, { useEffect, useState } from "react";
import MetaData from "../layouts/MetaData";
import Reveal from "../layouts/Reveal";
import { useTranslation } from "react-i18next";

const AboutUs = () => {
  const [language, setLanguage] = useState(
    localStorage.getItem("language") ? localStorage.getItem("language") : "tr"
  );

  const { t, i18n } = useTranslation("global");
  const arrayData = t("aboutUsPage.items", { returnObjects: true });

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language, i18n]);
  return (
    <div className="min-h-screen">
      <MetaData title={"Hakkımızda"} />

      <div className="">
        <img
          src="/images/about-us-img.png"
          alt="img"
          className="w-full h-[500px] object-cover"
        />
      </div>
      <div className="text-center mt-10">
        <Reveal>
          <div className="mb-10 px-10">
            <h1 className="text-4xl font-bold mb-5">
              {t("aboutUsPage.title")}
            </h1>
            <p>{t("aboutUsPage.text")}</p>
          </div>
        </Reveal>
        <div className="grid max-md:grid-cols-1 grid-cols-2 max-md:px-10 px-32 gap-10">
          {arrayData?.map((data) => (
            <Reveal>
              <div className="flex flex-col gap-10">
                <h1 className="text-4xl font-bold">{data?.title}</h1>
                <p>{data?.desc}</p>
                <img src={data?.img} alt="" className="rounded-md" />
              </div>
            </Reveal>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
