import React, { useEffect, useState } from "react";
import Reveal from "./Reveal";
import { useTranslation } from "react-i18next";

const FunFact = () => {
  const [language, setLanguage] = useState(
    localStorage.getItem("language") ? localStorage.getItem("language") : "tr"
  );
  const { t, i18n } = useTranslation("global");
  const itemsCount = t("funFactItems", { returnObjects: true }).length;

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language, i18n]);
  return (
    <Reveal>
      <div className="flex flex-col items-center justify-center mt-20">
        <div className="text-center max-md:mb-10 mb-20">
          <p className="text-orange-500">{t("funFactHeader.text")}</p>
          <h1 className="text-4xl font-bold">{t("funFactHeader.title")}</h1>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 max-md:gap-16 gap-28 text-center">
          {Array.from({ length: itemsCount }, (_, index) => (
            <Reveal>
              <div className="">
                <h1 className="text-7xl font-bold">
                  {t(`funFactItems.${index}.title`)}
                </h1>
                <p className="text-[#999]">{t(`funFactItems.${index}.text`)}</p>
              </div>
            </Reveal>
          ))}
        </div>
      </div>
    </Reveal>
  );
};

export default FunFact;
