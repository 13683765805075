import React, { useEffect, useState } from "react";
import Reveal from "./Reveal";
import { useTranslation } from "react-i18next";

const AboutSection = () => {
  const [language, setLanguage] = useState(
    localStorage.getItem("language") ? localStorage.getItem("language") : "tr"
  );
  const { t, i18n } = useTranslation("global");
  const itemsCount = t("aboutItems", { returnObjects: true }).length;

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language, i18n]);
  return (
    <Reveal>
      <div className="max-md:mt-10 mt-32 flex max-lg:flex-col max-md:gap-10 gap-20 max-md:px-10">
        <div className="">
          <img
            src="/images/sustainable-img.png"
            className="max-md:h-[400px] h-[700px] w-full lg:w-[600px] xl:w-[800px]"
            alt=""
          />
        </div>
        <div className="max-md:text-center flex-1">
          <div className="text-center max-md:mb-10 mb-20">
            <p className="text-orange-500">{t("aboutHeader.text")}</p>
            <h1 className="text-4xl font-bold mb-10">
              {t("aboutHeader.title")}
            </h1>
          </div>

          <div className="grid max-md:grid-cols-1 grid-cols-2 gap-10 text-center">
            {Array.from({ length: itemsCount }, (_, index) => (
              <Reveal>
                <div className="max-md:flex flex-col items-center">
                  <i className="ri-leaf-line text-8xl"></i>
                  <h1 className="text-2xl font-bold">
                    {t(`aboutItems.${index}.title`)}
                  </h1>
                  <p>{t(`aboutItems.${index}.text`)}</p>
                </div>
              </Reveal>
            ))}
          </div>
        </div>
      </div>
    </Reveal>
  );
};

export default AboutSection;
