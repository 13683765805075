import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { blogs } from "../../constants/blogs";
import Reveal from "./Reveal";
import { useTranslation } from "react-i18next";

const Blogs = () => {
  const [language, setLanguage] = useState(
    localStorage.getItem("language") ? localStorage.getItem("language") : "tr"
  );
  const { t, i18n } = useTranslation("global");
  const itemsCount = t("blogs", { returnObjects: true }).length;

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language, i18n]);

  let settings = {
    dots: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 740,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Reveal>
      <div className="max-md:mt-10 mt-32 xl:px-64">
        <div className="blogs-card flex items-center flex-col mb-10 max-md:mx-5">
          <h1 className="text-4xl font-bold ">{t("blogHeader.title")}</h1>
          <p>{t("blogHeader.text")}</p>
        </div>
        <div className="">
          <Reveal>
            <div className="my-10">
              <Slider {...settings}>
                {Array.from({ length: itemsCount }, (_, index) => (
                  <a href={`/blog/${t(`blogs.${index}.slug`)}`}>
                    <div className="px-3 cursor-pointer hover:scale-95 transition-all">
                      <img
                        src={t(`blogs.${index}.img`)}
                        alt="slider-img"
                        className=" "
                      />
                      <div className="blogs-card">
                        <h1 className="text-xl font-bold">
                          {t(`blogs.${index}.title`)}
                        </h1>
                        <p>{t(`blogs.${index}.subtitle`)} </p>
                      </div>
                    </div>
                  </a>
                ))}
              </Slider>
            </div>
          </Reveal>
        </div>
      </div>
    </Reveal>
  );
};

export default Blogs;
