import React, { useEffect, useState } from "react";
import Reveal from "./Reveal";
import { useTranslation } from "react-i18next";

const ParallaxLayout = () => {
  const [language, setLanguage] = useState(localStorage.getItem("language") ? localStorage.getItem("language") : "tr");
  const { t, i18n } = useTranslation("global");

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language, i18n]);
  return (
    <div className="parallax bg-sec1">
      <div className="content">
        <Reveal>
          <h1>{t("parallaxText")}</h1>
        </Reveal>
      </div>
    </div>
  );
};

export default ParallaxLayout;
