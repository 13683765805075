import React from "react";
import Slider from "../layouts/SliderLayout";
import SectionAreaOne from "../layouts/SectionAreaOne";
import ParallaxLayout from "../layouts/ParallaxLayout";
import Blogs from "../layouts/Blogs";
import { RiShieldUserLine } from "react-icons/ri";
import Reveal from "../layouts/Reveal";
import MetaData from "../layouts/MetaData";
import FunFact from "../layouts/FunFact";
import AboutSection from "../layouts/AboutSection";

const Home = () => {
  return (
    <div className="overflow-hidden">
      <MetaData title={"Anasayfa"} />
      <Slider />

      <SectionAreaOne />

      <ParallaxLayout />

      <FunFact />

      <AboutSection />

      <Blogs />
    </div>
  );
};

export default Home;
