import React, { useEffect, useState } from "react";
import Location from "../layouts/Location";
import MetaData from "../layouts/MetaData";
import Reveal from "../layouts/Reveal";
import { useTranslation } from "react-i18next";

const Communication = () => {
  const [language, setLanguage] = useState(
    localStorage.getItem("language") ? localStorage.getItem("language") : "tr"
  );
  const { t, i18n } = useTranslation("global");

  const itemsLeft = t("communicationPage.left.items", { returnObjects: true });
  const itemsRight = t("communicationPage.right.items", {
    returnObjects: true,
  });

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language, i18n]);

  return (
    <div>
      <MetaData title={`İletişim`} />

      <div className="">
        <img
          src="/images/communication-img.png"
          alt="img"
          className="w-full h-[500px] object-cover"
        />
      </div>

      <div className="">
        <Reveal>
          <div className="p-10">
            <h1 className="text-center text-4xl font-bold max-md:mb-3">
              {t("communicationPage.title")}
            </h1>
            <p>{t("communicationPage.text")}</p>
          </div>
        </Reveal>

        <Reveal>
          <div className="lg:px-64">
            <Location />
          </div>
        </Reveal>

        <div className="flex max-sm:flex-col max-sm:gap-10 justify-between px-10 lg:px-64 mt-10">
          <div className="border p-5 rounded bg-gradient-to-r from-orange-500 to-orange-400">
            <Reveal>
              <h1 className="text-2xl font-bold text-white">
                <p>{t("communicationPage.left.title")}</p>
              </h1>
            </Reveal>
            <Reveal>
              <div className="flex flex-col gap-5">
                {itemsLeft?.map((item) => (
                  <div className="flex gap-2 items-center">
                    <i className={item?.icon} />
                    <div className="">
                      <h1 className="font-bold">{item?.title}</h1>
                      <p>{item?.address}</p>
                    </div>
                  </div>
                ))}
              </div>
            </Reveal>
          </div>
          <Reveal>
            <div className="border p-5 rounded">
              <h1 className="text-2xl font-bold text-end">
              <p>{t("communicationPage.right.title")}</p>
              </h1>
              <div className="flex flex-col items-end gap-5 text-end">
                {itemsRight?.map((item) => (
                  <div className="flex gap-2 items-center">
                    <div className="">
                      <h1 className="font-bold">{item?.title}</h1>
                      <p>{item?.address}</p>
                    </div>
                    <i className={item?.icon} />
                  </div>
                ))}
              </div>
            </div>
          </Reveal>
        </div>
      </div>
    </div>
  );
};

export default Communication;
