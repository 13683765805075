import React, { useEffect, useState } from "react";
import MetaData from "../layouts/MetaData";
import Reveal from "../layouts/Reveal";
import { useTranslation } from "react-i18next";

const OurActivities = () => {
  const [language, setLanguage] = useState(
    localStorage.getItem("language") ? localStorage.getItem("language") : "tr"
  );
  const { t, i18n } = useTranslation("global");

  const itemsLeft = t("ourActivitiesPage.left.items", { returnObjects: true });
  const itemsRight = t("ourActivitiesPage.right.items", {
    returnObjects: true,
  });

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language, i18n]);

  return (
    <div>
      <MetaData title={`Amacımız - Faaliyetlerimiz`} />

      <div className="">
        <img
          src="/images/2.png"
          alt="img"
          className="w-full h-[500px] object-cover"
        />
      </div>

      <div className=" mt-10">
        <Reveal>
          <div className="text-center max-xl:px-10">
            <h1 className="text-4xl font-bold mb-5">
              {t("ourActivitiesPage.title")}
            </h1>
            <p>{t("ourActivitiesPage.text")}</p>
          </div>
        </Reveal>
        <div className="">
          <div className="text-start mt-10 max-xl:px-10 xl:px-64">
            <div className="">
              <div className="flex flex-col gap-10 justify-center">
                <div className="flex max-lg:flex-col gap-10 lg:justify-center lg:gap-16 items-center">
                  <Reveal>
                    <img
                      src={t("ourActivitiesPage.right.img")}
                      className="w-[700px] rounded-md"
                      alt="img"
                    />
                  </Reveal>
                  <div className="">
                    <h1 className="max-md:mb-2 mb-10 font-bold text-xl">
                      {t("ourActivitiesPage.left.title")}
                    </h1>

                    {itemsLeft.map((data) => (
                      <Reveal>
                        <div className="flex items-center gap-2">
                          <i class="ri-check-line text-orange-500 text-2xl font-bold" />
                          <p>{data?.text}</p>
                        </div>
                      </Reveal>
                    ))}
                  </div>
                </div>

                <div className="flex max-lg:flex-col gap-10 lg:justify-center lg:gap-16 items-center">
                  <div className="max-lg:hidden">
                    {itemsRight.map((data) => (
                      <Reveal>
                        <div className="flex items-center gap-2">
                          <i class="ri-check-line text-orange-500 text-2xl font-bold" />
                          <p>{data?.text}</p>
                        </div>
                      </Reveal>
                    ))}
                  </div>
                  <img
                    src={t("ourActivitiesPage.left.img")}
                    className="w-[700px] rounded-md"
                    alt="img"
                  />

                  <div className="lg:hidden">
                    {itemsRight.map((data) => (
                      <Reveal>
                        <div className="flex items-center gap-2">
                          <i class="ri-check-line text-orange-500 text-2xl font-bold" />
                          <p>{data?.text}</p>
                        </div>
                      </Reveal>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurActivities;
