import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const SliderLayout = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const sliderItems =
    windowWidth < 768
      ? [
          { img: "/images/slider-img-mobile-1.png" },
          { img: "/images/slider-img-mobile-2.png" },
          { img: "/images/slider-img-mobile-3.png" },
          { img: "/images/slider-img-mobile-4.png" },
        ]
      : [
          { img: "/images/slider-img-1.png" },
          { img: "/images/slider-img-2.png" },
          { img: "/images/slider-img-3.png" },
          { img: "/images/slider-img-4.png" },
        ];

  let settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };
  return (
    <div className="relative -z-10 overflow-hidden ">
      <Slider {...settings}>
        {sliderItems?.map((item) => (
          <div>
            <img
              src={item?.img}
              alt="slider-img"
              className="w-full sm:max-h-[500px] relative object-"
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default SliderLayout;
