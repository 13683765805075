import React, { useEffect } from "react";
import Reveal from "../../components/layouts/Reveal";
import { useTranslation } from "react-i18next";

const SectionAreaOne = () => {
  const { t, i18n } = useTranslation("global");
  const sectionCount = t("sectionAreaOne", { returnObjects: true }).length;

  useEffect(() => {
    i18n.changeLanguage(
      localStorage.getItem("language") ? localStorage.getItem("language") : "tr"
    );
  }, [i18n]);

  return (
    <section className="my-10">
      <div className="grid grid-cols-1 sm:grid-cols-2 max-lg:px-8 max-lg:gap-8 px-64 gap-20">
        {Array.from({ length: sectionCount }, (_, index) => (
          <Reveal key={index}>
            <div className="">
              <div className="flex items-center gap-4">
                <i
                  className={`text-8xl ${t(`sectionAreaOne.${index}.icon`)}`}
                />
                <h1 className="max-md:text-2xl text-3xl lg:text-4xl font-bold">
                  {t(`sectionAreaOne.${index}.title`)}
                </h1>
              </div>
              <div className="">
                <p>{t(`sectionAreaOne.${index}.desc`)}</p>
              </div>
            </div>
          </Reveal>
        ))}
      </div>
    </section>
  );
};

export default SectionAreaOne;
