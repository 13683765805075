import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const [language, setLanguage] = useState(
    localStorage.getItem("language") ? localStorage.getItem("language") : "tr"
  );
  const { t, i18n } = useTranslation("global");

  const pages = t("footer.pages.pageItems", { returnObjects: true });
  const footerSocialItems = t("footer.social.footerSocialItems", {
    returnObjects: true,
  });
  const itemsRight = t("communicationPage.right.items", {
    returnObjects: true,
  });

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language, i18n]);
  return (
    <div className="border-t mt-10 overflow-hidden">
      <div className="grid max-sm:grid-cols-1 grid-cols-2 max-lg:px-12 gap-10 max-lg:mb-10 lg:flex lg:px-32 lg:justify-between my-10">
        <div className="flex flex-col gap-4">
          <a href="/" className="">
            <img src="/images/logo.png" alt="logo-img" className="w-80" />
          </a>
          <i className="">{t("footer.text")}</i>
        </div>
        <div className="flex flex-col">
          <h1 className="text-3xl font-bold">{t("footer.pages.title")}</h1>

          {pages?.map((page) => (
            <a href={page.url} className="hover:text-orange-500 transition-all">
              {page.text}{" "}
            </a>
          ))}
        </div>
        <div className="">
          <h1 className="text-3xl font-bold">{t("footer.social.title")}</h1>
          <div className="">
            {footerSocialItems?.map((item) => (
              <a
                href={item.url}
                className={`flex items-center transition-all mb-2 ${item.style}`}
                target="_blank"
              >
                <i className={`text-4xl ${item.icon}`}></i> <p>{item.name}</p>
              </a>
            ))}
          </div>
        </div>
        <div className="">
          <h1 className="text-3xl font-bold">{t("footer.social.title")}</h1>
          <p className="mb-2">+90 534 589 15 25</p>
          <p className="mb-2">iletisim@goong.com.tr</p>
          <div className="mt-2">
            <h1 className="font-bold">{t("footer.contact.items.0.text")}</h1>
            <p>Mehmet nesih Özmen mah. nar sok 6/6 vip çarşı Merter İstanbul</p>
          </div>
          <div className="mt-2">
            <h1 className="font-bold">{t("footer.contact.items.1.text")}</h1>
            <p>Çepni mah.inönü cad. No 20 Çorum merkez</p>
          </div>
          <div className="mt-2">
            <h1 className="font-bold">{t("footer.contact.items.2.text")}</h1>
            <p>Yeniyol Mahallesi Gazi Caddesi No:12/d Çorum/Merkez</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
