import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { blogs } from "../../constants/blogs";
import Reveal from "../layouts/Reveal";
import MetaData from "../layouts/MetaData";
import { useTranslation } from "react-i18next";

const BlogDetails = () => {
  const { slug } = useParams();
  
  const [language, setLanguage] = useState(
    localStorage.getItem("language") ? localStorage.getItem("language") : "tr"
  );

  const { t, i18n } = useTranslation("global");
  const blogs = t("blogs", { returnObjects: true }); // Blog verilerini al
  const blog = blogs.find((blog) => blog.slug === slug);

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language, i18n]);

  return (
    <>
      <MetaData title={`Blog Detayı`} />

      <Reveal>
        <div className="flex justify-center ">
          <div className="md:w-2/3 mt-10 mx-10">
            <div className="">
              <h1 className="text-6xl font-bold text-center">{blog?.title}</h1>
            </div>
            <img src={blog?.img} alt="blog_img" className="my-10 w-full" />
            <div className="">
              <p
                className="text-center"
                dangerouslySetInnerHTML={{ __html: blog?.desc }}
              ></p>
            </div>
          </div>
        </div>
      </Reveal>
    </>
  );
};

export default BlogDetails;
